
import { computed, defineComponent, ref } from 'vue';
import { mdiPencil, mdiDelete, mdiMagnify } from '@mdi/js';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import dayjs from 'dayjs';

type TranslationRecord = {
  id: number;
  variable: string;
  domain: string;
  de: string;
  en: string;
  createdat: Date;
};

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();

    const active = ref(-1);
    const deleteconfirm = ref(-1);

    const de = ref('');
    const en = ref('');
    const domain = ref('');
    const variable = ref('');

    const translations = ref<Array<TranslationRecord>>([]);
    const publishdate = (date: Date) =>
      dayjs(date)
        .locale('de')
        .format('DD. MMMM YYYY HH:MM');

    const filter = ref('');
    const filter2 = ref(0);
    const filteredtranslations = computed(() => {
      let trans = translations.value;

      if (filter.value != '') {
        trans = trans.filter((translation: TranslationRecord) => {
          return active.value == translation.id || translation.de.toLowerCase().includes(filter.value.toLowerCase()) || translation.en.toLowerCase().includes(filter.value.toLowerCase()) || translation.variable.toLowerCase().includes(filter.value.toLowerCase()) || translation.domain.toLowerCase().includes(filter.value.toLowerCase());
        });
      }

      if (filter2.value == 1) {
        // Erstellungsdatum;
        return trans.sort((a: TranslationRecord, b: TranslationRecord) => {
          if (a > b) return -1;
          if (a < b) return 1;
          return 0;
        });
      } else if (filter2.value == 2) {
        //Nicht vollständig
        return trans.filter((translation: TranslationRecord) => active.value == translation.id || !translation.de || translation.de == '' || !translation.en || translation.en == '');
      } else {
        return trans;
      }
    });

    store.dispatch('fetchTranslations').then((res: Array<TranslationRecord>) => {
      translations.value = res;
    });

    const saveDomain = () => {
      store.dispatch('insertTranslation', { de: de.value, en: en.value, domain: domain.value, variable: variable.value }).then(() => {
        store.dispatch('addNotification', { message: 'Übersetzung gespeichert' });
        de.value = '';
        en.value = '';
        domain.value = '';
        variable.value = '';
        store.dispatch('fetchTranslations').then((res: Array<TranslationRecord>) => {
          translations.value = res;
        });
      });
    };

    const edit = (id: number) => {
      active.value = id;
    };

    const save = (translation: TranslationRecord) => {
      store.dispatch('updateTranslation', translation).then(() => {
        store.dispatch('addNotification', { message: 'Übersetzung gespeichert' });
        de.value = '';
        en.value = '';
        domain.value = '';
        variable.value = '';
        active.value = -1;
        store.dispatch('fetchTranslations').then((res: Array<TranslationRecord>) => {
          translations.value = res;
        });
      });
    };

    const del = (id: number) => {
      if (deleteconfirm.value == id) {
        store.dispatch('deleteTranslation', id).then(() => {
          store.dispatch('addNotification', { message: 'Übersetzung gelöscht' });
          deleteconfirm.value = -1;
          store.dispatch('fetchTranslations').then((res: Array<TranslationRecord>) => {
            translations.value = res;
          });
        });
      } else {
        deleteconfirm.value = id;
        setTimeout(() => {
          deleteconfirm.value = -1;
        }, 2000);
      }
    };

    return {
      mdiPencil,
      mdiDelete,
      mdiMagnify,
      de,
      en,
      domain,
      routes: router.options.routes,
      variable,
      saveDomain,
      save,
      edit,
      active,
      deleteconfirm,
      del,
      filteredtranslations,
      publishdate,
      filter,
      filter2,
    };
  },
});
