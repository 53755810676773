<style lang="scss">
.profil-overview {
  .img-box {
    width: 350px;
    height: 350px;
    background-size: cover;
    background-position: center center;
    border-radius: 50%;
    margin: 40px auto;
    position: relative;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: $coral;
      position: absolute;
      left: -10px;
      top: 10px;
      border-radius: 50%;
      z-index: -1;
    }
  }
  h2 {
    margin-bottom: 20px;
    span {
      display: inline-block;
      font-family: 'Helvetica Neue LT W05 65 Medium';
    }
  }
  .scale-in-profil {
    &:after {
      animation-name: scale-in-profil;
      animation-duration: 2s;
      animation-fill-mode: forwards;
      opacity: 0;
      animation-delay: 0.5s;
    }
  }

  @keyframes scale-in-profil {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0%);
    }
  }
}
</style>

<template>
  <div class="container-fluid profil-overview">
    <div class="row">
      <div class="col-12">
        <div class="headline-h1">
          <h1>Profil</h1>
          <div class="line"><span>Übersicht</span></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-7 align-self-center">
        <div class="img-box scale-in-profil" style="background-image:url(https://picsum.photos/600/400);"></div>
      </div>
      <div class="col-lg-5 align-self-center">
        <h2>Maximilian <span>Mustermann</span></h2>
        <div class="form-group"><span class="label-input">E-mail Adresse</span> <input type="text" value="maximilian.mustermann@styria.com" /></div>
        <div class="form-group"><span class="label-input">Name - Firma</span> <input type="text" value="not yet implemented" /></div>
        <div class="form-group"><span class="label-input">Abteilung - Firma</span> <input type="text" value="not yet implemented" /></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
