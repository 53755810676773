<style lang="scss">
#backend {
  .author-all {
    .img-box-preview {
      width: 150px;
      margin: 0 auto;
      img {
        display: block;
        border-radius: 50%;
        width: 100%;
        margin: 0 auto;
      }
    }
    .career-inner {
      transition: $transition-std;
      border-bottom: 1px solid #eee;
      padding: 25px 15px;
      //margin-bottom:10px;
      &:last-child {
        border-bottom: 0;
      }
      .btn {
        border: 1px solid #eee;
        margin-right: 5px;
        svg {
          width: 20px;
          height: 20px;
          fill: #888;
        }
        &:hover {
          svg {
            fill: #333;
          }
        }
      }
      .btn-edit {
        margin-bottom: 5px;
      }
      .name {
        //border:1px solid blue;
        font-size: 120%;
        font-family: 'Helvetica Neue LT W05 65 Medium';
      }

      .img-box {
        //border:1px solid red;
        width: 120px;
        height: 120px;
        margin: 0 auto;

        img {
          //border:1px solid blue;
          width: 100%;
          display: block;
          margin: 0 auto;
          border-radius: 50%;
        }
      }
      .link {
        text-align: center;
        a {
          display: block;
          transition: $transition-std;
          svg {
            fill: #888;
            width: 30px;
            height: 30px;
          }
        }
      }
      &:hover {
        background: #f1f1f1;
        a {
          padding-left: 25px;
          svg {
            fill: #000;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="container-fluid author-all">
    <div class="headline-h1">
      <h1>Author hinzufügen</h1>
      <div class="line"><span>Authoren</span></div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group"><span class="label-input">Titel Beruf </span> <input type="text" value="not yet implemented" /></div>
      </div>
      <div class="col-lg-6">
        <div class="form-group"><span class="label-input">Details</span> <input type="text" value="not yet implemented" /></div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <span class="label-input">Bild Upload </span>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="img-box-preview"><img src="https://picsum.photos/300/300" alt="test" class="img-fluid" /></div>
      </div>
    </div>
    <div class="row justify-content-start align-self-center">
      <div class="col-lg-4 col-md-6">
        <button class="btn-std btn-color-save">Speichern</button>
      </div>
    </div>

    <br /><br /><br />
    <div class="headline-h1">
      <h1>Übersicht</h1>
      <div class="line"><span>Authoren</span></div>
    </div>
    <div class="career-overview">
      <!-- -->
      <div class="career-inner">
        <div class="row">
          <div class="col-lg-1 justify-content-center align-self-center">
            <div class="btn btn-edit" title="Editieren"><Icon :path="mdiPencil" /></div>
            <div class="btn btn-delete" title="Löschen"><Icon :path="mdiDelete" /></div>
          </div>
          <div class="col-lg-3 justify-content-center align-self-center">
            <div class="img-box">
              <img src="https://picsum.photos/200/200" alt="test" class="img-fluid" />
            </div>
          </div>
          <div class="col-lg-7 justify-content-center align-self-center">
            <div class="name">Maximilian Mustermann</div>
            <div class="info-txt">Resort Kleine Zeitung // Politik und Sport</div>
          </div>

          <div class="col-lg-1 justify-content-end align-self-center">
            <div class="link">
              <a href="">
                <Icon :path="mdiArrowRight" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mdiPencil, mdiDelete, mdiEyeOff, mdiArrowRight } from '@mdi/js';

export default defineComponent({
  components: {},
  setup() {
    return {
      mdiPencil,
      mdiDelete,
      mdiEyeOff,
      mdiArrowRight,
    };
  },
});
</script>
