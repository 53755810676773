<style lang="scss">
#backend {
  .language-variables {
    .filter-box {
      //border:1px solid red;
      background: #eee;
      margin-bottom: 10px;
      padding: 15px;
      .input-group {
        .form-control {
          padding: 0.7em 0.8em;
          border: 1px solid #eee;
          border-radius: 0;
          letter-spacing: 1px;
          ::placeholder {
            letter-spacing: 2px;
          }
          @media (max-width: $width-md) {
            margin-bottom: 10px;
          }
          &:focus {
            //border:1px solid #888;
            outline: none;
            box-shadow: none;
            box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.15) !important;
          }
        }
        .input-group-append {
          .input-group-text {
            border: 1px solid #eee;
            background: #ccc;
            padding: 0.7em 0.8em;
            border-radius: 0;
            svg {
              fill: #fff;
            }
          }
        }
      }
      .form-select {
        padding: 0.7em 0.8em;
        border-radius: 0;
        border: 1px solid #fff;
        &:focus {
          box-shadow: none;
          border-color: #888;
        }
      }
    }
    .info-lang-outer {
      margin-bottom: 10px;
      @media (max-width: $width-md) {
        display: none;
      }
      .info-lang {
        background: #eee;
        font-size: 80%;
        letter-spacing: 2px;
        text-transform: uppercase;
        padding: 5px 0;
        color: #aaa;
        text-align: center;
        margin-bottom: 3px;
        font-style: italic;
      }
    }
    .language-variables-overview {
      .language-variables-inner {
        //border:1px solid blue;
        margin-bottom: 30px;
        transition: $transition-std;
        padding: 15px;

        &:hover {
          background: #f9f9f9;
        }
      }
      .language-variables-details {
        //border:1px solid red;
        border-bottom: 1px solid #eee;
        margin-bottom: 10px;
        padding-bottom: 5px;
        font-size: 90%;

        .btn-outer {
          @media (max-width: $width-sm) {
            text-align: center;
          }
          .btn {
            border: 1px solid #eee;
            margin-right: 5px;
            svg {
              width: 20px;
              height: 20px;
              fill: #888;
              position: relative;
              top: -2px;
            }
            &:hover {
              svg {
                fill: #333;
              }
            }
          }
        }
        .date {
          font-size: 90%;
          @media (max-width: $width-md) {
            text-align: right;
          }
          @media (max-width: $width-sm) {
            text-align: center;
            padding: 3px 0;
          }
        }
        .text-variable {
          text-align: right;
          @media (max-width: $width-md) {
            text-align: center;
          }

          span {
            display: inline-block;
            padding: 5px 5px;
            background: #eee;
            @media (max-width: $width-md) {
              display: block;
              margin-top: 3px;
            }
          }
        }
      }
      textarea {
        display: block;
        width: 100%;
        border-radius: 0 !important;
        border: 1px solid #eee;
        padding: 0.7em 0.8em;
        outline: none;
        &:focus {
          border-radius: 0 !important;
          border: 1px solid $font-color-std;
          box-shadow: none;
        }
      }
      input {
        border-radius: 0 !important;
        border: 1px solid #eee;
        padding: 0.3em 0.3em;
        outline: none;
        &:focus {
          border-radius: 0 !important;
          border: 1px solid $font-color-std;
          box-shadow: none;
        }
      }
      .col-lang-de {
        //border:1px solid red;
        @media (max-width: $width-md) {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="container-fluid language-variables">
    <div class="row" v-hasperm="'i18n.edit.domain'">
      <div class="col-12">
        <div class="headline-h1">
          <h1>Sprachen / Text</h1>
          <div class="line"><span>Eingabe</span></div>
        </div>
      </div>
    </div>
    <div class="row" v-hasperm="'i18n.edit.domain'">
      <div class="col-lg-12 align-self-center">
        <div class="form-group"><span class="label-input">Text - Deutsch</span> <input type="text" v-model="de" /></div>
      </div>
      <div class="col-lg-12 align-self-center">
        <div class="form-group"><span class="label-input">Text - Englisch</span> <input type="text" v-model="en" /></div>
      </div>
      <div class="col-lg-6 align-self-center" v-hasperm="'i18n.edit.domain'">
        <div class="form-group"><span class="label-input">Domäne (Routename)</span> <input type="text" v-model="domain" /></div>
      </div>
      <div class="col-lg-6 align-self-center" v-hasperm="'i18n.edit.domain'">
        <div class="form-group"><span class="label-input">Variable</span> <input type="text" v-model="variable" /></div>
      </div>
    </div>
    <div class="row justify-content-start align-self-center" v-hasperm="'i18n.edit.domain'">
      <div class="col-lg-4 col-md-6">
        <button class="btn-std btn-color-save" @click="saveDomain">{{ $t('General.save_btn') }}</button>
      </div>
    </div>
    <br /><br />
    <div class="row">
      <div class="col-12">
        <div class="headline-h1">
          <h1>Sprachen / Text</h1>
          <div class="line"><span>Übersicht</span></div>
        </div>
      </div>
    </div>
    <div class="filter-box">
      <div class="row">
        <div class="col-lg-8 col-md-7 align-self-center">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Volltextsuche" v-model="filter" />
            <div class="input-group-append">
              <span class="input-group-text"><Icon :path="mdiMagnify" /> </span>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-5 align-self-center">
          <select class="form-select" aria-label="Default select example" v-model="filter2">
            <option value="0">Filter</option>
            <option value="1">Erstellungsdatum</option>
            <option value="2">Nicht vollständig</option>
          </select>
        </div>
      </div>
    </div>
    <div class="info-lang-outer">
      <div class="row">
        <div class="col-md-6">
          <div class="info-lang">DEUTSCH</div>
        </div>
        <div class="col-md-6">
          <div class="info-lang">ENGLISCH</div>
        </div>
      </div>
    </div>
    <div class="language-variables-overview">
      <div class="language-variables-inner" :key="translation.id" v-for="(translation, index) in filteredtranslations">
        <div class="row language-variables-details g-0">
          <div class="col-lg-3 col-md-3 col-sm-6 align-self-center btn-outer">
            <div v-hasperm="'i18n.edit.text'" class="btn btn-edit" title="Editieren" @click="edit(translation.id)" v-if="active != translation.id"><Icon :path="mdiPencil" /></div>
            <div v-hasperm="'i18n.edit.text'" class="btn btn-color-save" title="Speichern" @click="save(translation)" v-if="active == translation.id">Speichern</div>
            <div v-hasperm="'i18n.edit.domain'" class="btn btn-delete" title="Löschen" v-if="deleteconfirm != translation.id" @click="del(translation.id)"><Icon :path="mdiDelete" /></div>
            <div v-hasperm="'i18n.edit.domain'" class="btn btn-delete" title="Löschen" v-if="deleteconfirm == translation.id" @click="del(translation.id)">Wirklich löschen?</div>
          </div>
          <div class="col-lg-4 col-md-3 col-sm-6 align-self-center">
            <div class="date">{{ publishdate(translation.createdat) }}</div>
          </div>
          <div class="col-lg-5 col-md-6 col-sm-12 align-self-center">
            <div class="text-variable" v-hasperm="'i18n.edit.domain'">
              <span v-if="active != translation.id">{{ translation.domain }}.{{ translation.variable }}</span>
              <span v-if="active == translation.id"><input type="text" v-model="filteredtranslations[index].domain"/>.<input type="text" v-model="filteredtranslations[index].variable"/></span>
            </div>
            <div class="text-variable" v-hasperm.not="'i18n.edit.domain'">
              <span>{{ translation.domain }}.{{ translation.variable }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 align-self-center col-lang-de">
            <div class="text" v-if="active != translation.id">
              {{ translation.de }}
            </div>
            <div class="text" v-if="active == translation.id">
              <textarea v-model="filteredtranslations[index].de" />
            </div>
          </div>
          <div class="col-md-6 align-self-center col-lang">
            <div class="text" v-if="active != translation.id">
              {{ translation.en }}
            </div>
            <div class="text" v-if="active == translation.id">
              <textarea v-model="filteredtranslations[index].en" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { mdiPencil, mdiDelete, mdiMagnify } from '@mdi/js';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import dayjs from 'dayjs';

type TranslationRecord = {
  id: number;
  variable: string;
  domain: string;
  de: string;
  en: string;
  createdat: Date;
};

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter();
    const store = useStore();

    const active = ref(-1);
    const deleteconfirm = ref(-1);

    const de = ref('');
    const en = ref('');
    const domain = ref('');
    const variable = ref('');

    const translations = ref<Array<TranslationRecord>>([]);
    const publishdate = (date: Date) =>
      dayjs(date)
        .locale('de')
        .format('DD. MMMM YYYY HH:MM');

    const filter = ref('');
    const filter2 = ref(0);
    const filteredtranslations = computed(() => {
      let trans = translations.value;

      if (filter.value != '') {
        trans = trans.filter((translation: TranslationRecord) => {
          return active.value == translation.id || translation.de.toLowerCase().includes(filter.value.toLowerCase()) || translation.en.toLowerCase().includes(filter.value.toLowerCase()) || translation.variable.toLowerCase().includes(filter.value.toLowerCase()) || translation.domain.toLowerCase().includes(filter.value.toLowerCase());
        });
      }

      if (filter2.value == 1) {
        // Erstellungsdatum;
        return trans.sort((a: TranslationRecord, b: TranslationRecord) => {
          if (a > b) return -1;
          if (a < b) return 1;
          return 0;
        });
      } else if (filter2.value == 2) {
        //Nicht vollständig
        return trans.filter((translation: TranslationRecord) => active.value == translation.id || !translation.de || translation.de == '' || !translation.en || translation.en == '');
      } else {
        return trans;
      }
    });

    store.dispatch('fetchTranslations').then((res: Array<TranslationRecord>) => {
      translations.value = res;
    });

    const saveDomain = () => {
      store.dispatch('insertTranslation', { de: de.value, en: en.value, domain: domain.value, variable: variable.value }).then(() => {
        store.dispatch('addNotification', { message: 'Übersetzung gespeichert' });
        de.value = '';
        en.value = '';
        domain.value = '';
        variable.value = '';
        store.dispatch('fetchTranslations').then((res: Array<TranslationRecord>) => {
          translations.value = res;
        });
      });
    };

    const edit = (id: number) => {
      active.value = id;
    };

    const save = (translation: TranslationRecord) => {
      store.dispatch('updateTranslation', translation).then(() => {
        store.dispatch('addNotification', { message: 'Übersetzung gespeichert' });
        de.value = '';
        en.value = '';
        domain.value = '';
        variable.value = '';
        active.value = -1;
        store.dispatch('fetchTranslations').then((res: Array<TranslationRecord>) => {
          translations.value = res;
        });
      });
    };

    const del = (id: number) => {
      if (deleteconfirm.value == id) {
        store.dispatch('deleteTranslation', id).then(() => {
          store.dispatch('addNotification', { message: 'Übersetzung gelöscht' });
          deleteconfirm.value = -1;
          store.dispatch('fetchTranslations').then((res: Array<TranslationRecord>) => {
            translations.value = res;
          });
        });
      } else {
        deleteconfirm.value = id;
        setTimeout(() => {
          deleteconfirm.value = -1;
        }, 2000);
      }
    };

    return {
      mdiPencil,
      mdiDelete,
      mdiMagnify,
      de,
      en,
      domain,
      routes: router.options.routes,
      variable,
      saveDomain,
      save,
      edit,
      active,
      deleteconfirm,
      del,
      filteredtranslations,
      publishdate,
      filter,
      filter2,
    };
  },
});
</script>
